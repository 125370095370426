html, body {
    height: 100%;
    margin: 0;
    padding: 0;
    overflow-x: hidden;
    
}

.contact-container > *:not(footer) {
    flex: 1; 
}

.contact-us-backgroun-container{
    margin: 0 45px;
    padding: 20px;
    background-color: #E6E6E6;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 10px;  
    margin-bottom: 25px;  
    margin-top: 25px;  
}

.contact-us-elements{
    margin: 0 45px;
    padding: 20px;
    background-color: #E6E6E6;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 10px;  
    margin-bottom: 25px;  
    margin-top: 25px;   
}
.contact-us-title{
    text-align: center;
    font: normal normal medium 69px/91px Roboto;
    letter-spacing: 0px;
    color: #707070;
    opacity: 1;
}

.contact-us-info {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 40px; 
    padding: 50px;
    max-width: 1100px;
    margin: 0 auto;
    background: #FFFFFF;
    border-radius: 15px;
    box-shadow: 0px 3px 6px #00000029;
    position: relative; /* Necesario para la línea divisoria */
}

.contact-us-info::before {
    content: "";
    width: 1px;
    height: 80%;
    background-color: #ccc;
    position: absolute;
    left: 50%;
    top: 10%;
    transform: translateX(-50%);
}

.contact-us-right-info-title{
    text-align: left;
    font: normal normal medium 69px/91px Roboto;
    letter-spacing: 0px;
    color: #707070;
    opacity: 1;
    padding: 10px;
}

.contact-form h2{
    text-align: center;
    font: normal normal medium 69px/91px Roboto;
    letter-spacing: 0px;
    color: #707070;
    opacity: 1;
    padding: 10px;
}
.contact-us-left-info {
    flex: 1;
    display: flex;
    justify-content: center;
}

.contact-us-right-info {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    
}

.contact-us-whatsapp {
    display: flex;
    align-items: center;
    gap: 10px;
}
.contact-us-location{
    display: flex;
    align-items: center;
    gap: 10px;
}

.whatsapp-icon, .location-icon, .mail-icon {
    width: 50px;
    height: 50px;
    margin-right: 10px;
}

.contact-us-location h3,
.contact-us-email h3, 
.contact-us-whatsapp h3, 
.contact-us-address h3 {
    text-align: left;
    font: normal normal medium 61px/81px Roboto;
    letter-spacing: 0px;
    color: #707070;
    opacity: 1;
}

.contact-us-location p,
.contact-us-email p a, 
.contact-us-whatsapp p a {
    text-align: left;
    font: normal normal medium 61px/81px Roboto;
    letter-spacing: 0px;
    color: #707070;
    opacity: 1;
    font-size: 25px;
}

.contact-us-email p a:hover, 
.contact-us-whatsapp p a:hover {
    text-decoration: underline;
}

.contact-us-email {
    display: flex;
    align-items: center;
    gap: 10px; 
}

/* Contenedor del formulario */
.contact-form {
    background: #EEEEEE;
    border-radius: 26px;
    padding: 20px;
    width: 100%;
    max-width: 500px;
    box-shadow: 0px 3px 6px #00000029;
}


/* Estilo de los campos de entrada */
.contact-form input, 
.contact-form textarea {
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 16px;
}

/* Estilo de las filas para Nombre y Apellido */
.form-row {
    display: flex;
    gap: 10px;
}

.form-row input {
    flex: 1;
}

/* Ajuste del área de texto */
.contact-form textarea {
    height: 100px;
    resize: none;
}

/* Botón de enviar */
.btn-submit {
    width: 100%;
    padding: 10px;
    background-color: #007BFF;
    color: white;
    border: none;
    border-radius: 5px;
    font-size: 18px;
    cursor: pointer;
    transition: background 0.3s;
}

.btn-submit:hover {
    background-color: #0056b3;
}
