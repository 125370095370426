html, body {
    height: 100%;
    margin: 0;
    padding: 0;
    overflow-x: hidden;
    
}

.what-us-container {
    display: flex;
    flex-direction: column;
    min-height: 100vh; 

}

.what-us-container > *:not(footer) {
    flex: 1; 
}

.banner-what-us div {
    position: relative;
    width: 100%;
    max-width: 100vw;
    box-shadow: 0px 3px 6px #00000029;

}

.banner-what-us img {
    width: 100%; 
    height: auto;
    display: block;
    box-shadow: 0px 3px 6px #00000029;


}

.banner-what-us p {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    color: white; 
    padding: 10px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 4em; 
    background-color: rgba(0, 0, 0, 0.5);
    box-shadow: 0px 3px 6px #00000029;
    margin: 0; 

}

.what-us-elements{
    margin: 0 45px;
    padding: 20px;
    background-color: #E6E6E6;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 10px;  
    margin-bottom: 25px;  
    margin-top: 25px;  

}


.what-us-content-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    width: 100%;
    box-shadow: 0px 3px 6px #bebebe29;
    border-radius: 10px; 
    margin-top: 10px;
}

.title-whatus{
    text-align: center;
    margin: 25px;

}


.content-what-us{
    display: flex;
    margin-top: 20px;
    width: 80%;
    flex-wrap: wrap;
    padding: 10px;
}

.content-what-us-left {
    flex: 1 1 50%;
    padding-right: 20px;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 10px; 

}

.content-what-us-left h2 {
    margin-bottom: 10px;
    font-size: 18px;
    font-weight: bold;
    font-size: 1.8em; 
    margin: 20px 0;

}

.content-what-us-left p {
    margin-top: 10px;
    padding: 20px 0;
    font-size: 1.2em; 

}

.content-what-us-right {
    flex: 1 1 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.content-what-us-right img {
    max-width: 100%;
    height: 100%;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 10px; 
}
