.background-container-discounts {
    width: 100%;
    height: 100%;
    background: #F1F1F1;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 22px;
    opacity: 1;
    padding: 20px; 
}

.discount-title {
    text-align: center;
    font: normal normal bold 40px/47px Helvetica;
    letter-spacing: 0px;
    color: #707070;
    opacity: 1;
    margin-bottom: 20px; 
}

.form-control{
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
}

.discount-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    padding: 10px;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 10px;
    background-color: white;
}

.button-container {
    display: flex;
    width: 50%;
    justify-content: center;
    margin-top: 10px;
}

.delete-button-discount {
    width: 50%;
    height: 40px;
    background-color: red;
    color: white;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
}

/* Media Queries para responsividad */
@media (max-width: 768px) {
    .discount-title {
        font-size: 32px; /* Reducir tamaño de fuente para pantallas más pequeñas */
    }

    .discount-item {
        width: 90%; /* Asegurarse de que cada item ocupe el 90% en pantallas pequeñas */
    }

    .delete-button-discount {
        width: 90%; /* Ajustar el botón para que ocupe el 90% en pantallas pequeñas */
    }
}

@media (max-width: 480px) {
    .discount-title {
        font-size: 24px; /* Reducir aún más el tamaño de la fuente */
    }

    .delete-button-discount {
        height: 35px; /* Ajustar la altura del botón en pantallas pequeñas */
    }
}
