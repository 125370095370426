.background-container {
    width: 100%;
    height: 100%;
    background: #F1F1F1;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 22px;
    opacity: 1;
    padding: 20px; 
    margin-top: 25px;
}

.fullscreen-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.801); /* Fondo negro completo */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
}

.fullscreen-content {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}

.fullscreen-image {
    max-width: 80vw;
    max-height: 80vh;
    object-fit: contain;
    border-radius: 10px;
}


.close-button {
    position: absolute;
    top: 10px;
    right: 45px;
    border: none;
    border-radius: 50px;
    background: rgba(0, 0, 0, 0.596);
    color: white; 
    font-size: 2rem;
    font-weight: bold;
    cursor: pointer;
    padding: 10px;
    background: none; 
}

.arrow-button {
    position: absolute;
    font-size: 1rem;
    top: 50%;
    transform: translateY(-50%);
    border: none;
    border-radius: 100%;
    background: rgba(0, 0, 0, 0.596);
    color: white;
    cursor: pointer;
    z-index: 1000;
    padding: 10px;
}


.pagination-controls {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px; 
    margin: 20px 0; 
}

.pagination-controls button,
.pagination-controls select {
    margin: 0 5px;
}

.pagination-controls span {
    margin: 0 10px; 
}

.product-list-discounts{
    padding: 30px;
    display: flex;
    flex-direction: column;
    gap: 15px;
    border-radius: 15px; 
    border: 1px solid #ddd; 
    overflow: hidden; 
    background: #fff; 
}
.product-item-discounts{
    display: flex;
    align-items: center;
    border-bottom: 1px solid #ddd;
    padding: 5px 0;
}

.product-image-container-discount {
    position: relative;
    margin-right: 20px;
    width: 30%; 
    height: 50%; 
    overflow: hidden;
    cursor: pointer;
}

.center-image {
    width: 80%;
    height: 80%;
    object-fit: cover;
    transition: opacity 0.3s ease;
}

.side-imagen {
    position: absolute;
    top: 0;
    left: 0;
    width: 80%;
    object-fit: cover;
    opacity: 0;
    transition: opacity 0.3s ease;
}

.product-item-discounts:hover .side-imagen {
    opacity: 1;
}

.product-item-discounts:hover .center-image {
    opacity: 0;
}

.product-details-discount {
    display: flex;
    align-items: center;
    margin: 5%;
}

.description-list {
    flex: 1;
    padding-right: 50px;
}

.products-buy-details-discount {
    display: flex;
    flex-direction: column;
    align-items: center; 
    justify-content: center; 
    margin-right: 65px;
}

.product-info-discount {
    font: normal normal normal 22px/29px Roboto;
    color: #555;
    margin: 0; 
}

.product-price {
    font-size: 50px; 
    font: normal normal normal 32px/29px Roboto;
}

.shop-control-elements {
    display: flex;
    justify-content: center; /* Centra el botón horizontalmente */
    align-items: center;
}

.shop-control-elements button {
    margin-top: 20px; 
    margin-bottom: 20px; 
    display: flex;
    justify-content: center; /* Centra el botón horizontalmente */
    align-items: center; /* Centra el botón verticalmente si es necesario */
}

.shop-control-elements button:hover {
    background-color: #495057; /* Color de fondo del botón al pasar el ratón */
    border-color: #495057; /* Color del borde del botón al pasar el ratón */
}   

.shop-control-elements {
    display: flex;
    flex-direction: column; 
    align-items: center; 
    margin-top: 5px; 
    margin-bottom: 5px;
}

.button-container {
    margin-bottom: 5px; 
}

.quantity-selector-container {
    display: flex;
    flex-direction: row; 
    margin-left:5%;
    align-items: center;
    gap: 15px; 
    width: 100%; 
}

.quantity-selector-wrapper {
    position: relative;
    display: inline-block;
}
.quantity-input {
    width: 45px;
    text-align: center;
    border: 1px solid #ddd;
    padding: 5px;
}
.price-overlay {
    display: flex;
    flex-direction: column; 
    text-align: center;
    align-items: center;
}

.product-price-discount {
    color:#707070;
    
}

.shop-control-elements p {
    color:#414040;
    margin: 0;
}

/* Estilos para pantallas pequeñas (smartphones y dispositivos pequeños) */
@media (max-width: 768px) {
    .background-container {
        padding: 10px; /* Reduce el padding en contenedores más pequeños */
    }

    .product-list-discounts {
        padding: 10px; /* Ajusta el padding de la lista de productos */
    }

    .product-item-discounts {
        flex-direction: column; /* Cambia la dirección a columna */
        align-items: flex-start; /* Alinea a la izquierda */
    }

    .product-image-container-discount {
        width: 100%; /* Hace que la imagen ocupe todo el ancho */
        height: auto; /* Ajusta la altura automáticamente */
        margin-bottom: 10px; /* Espaciado inferior */
    }

    .center-image,
    .side-imagen {
        width: 100%; /* Imágenes ocupan el ancho completo */
        height: auto; /* Ajusta la altura automáticamente */
    }

    .product-details-discount {
        flex-direction: column; /* Coloca los detalles en columna */
        align-items: flex-start; /* Alinea a la izquierda */
        margin: 10px 0; /* Espaciado vertical */
    }

    .quantity-selector-container {
        margin-right: 20px;
        flex-direction: row; /* Alinea los selectores en columna */
        align-items: center; /* Alinea a la izquierda */
        width: 100%; /* Usa el 100% del ancho */
    }

    .description-list {
        padding-right: 0; /* Elimina padding derecho */
        margin-bottom: 15px; /* Espacio inferior */
    }

    .product-price {
        font-size: 40px; /* Tamaño de fuente ajustado */
    }

    .shop-control-elements {
        flex-direction: column; /* Coloca los controles en columna */
        width: 100%; /* Usa el 100% del ancho */
    }

    .pagination-controls {
        flex-direction: column; /* Pone los controles en columna */
        gap: 5px; /* Espacio entre controles */
    }
}