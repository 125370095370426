.background-container-shapes {
    width: 100%;
    height: 100%;
    background: #F1F1F1;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 22px;
    opacity: 1;
    padding: 20px; 
}

.shapes-title {
    text-align: center;
    font: normal normal bold 40px/47px Helvetica;
    letter-spacing: 0px;
    color: #707070;
    opacity: 1;
    margin-bottom: 20px; 
}

.form-control{
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
}

.shape-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    padding: 10px;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 10px;
    background-color: white;
}

.shape-image {
    width: 100%;
    border-radius: 10px;
    opacity: 1;
    margin-bottom: 10px;
}

.button-container {
    display: flex;
    width: 50%;
    justify-content: center;
    margin-top: 10px;
}

.delete-button-shape {
    width: 50%;
    height: 40px;
    background-color: red;
    color: white;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
}

/* Media Queries */
@media (max-width: 1200px) {
    .shapes-title {
        font-size: 32px; /* Tamaño de fuente más pequeño */
    }

    .button-container {
        width: 70%; /* Aumentar el ancho del contenedor del botón */
    }

    .delete-button-shape {
        font-size: 14px; /* Tamaño de fuente más pequeño */
    }
}

@media (max-width: 992px) {
    .shapes-title {
        font-size: 28px;
    }

    .button-container {
        width: 80%;
    }

    .delete-button-shape {
        width: 60%; /* Aumentar el ancho del botón */
    }
}

@media (max-width: 768px) {
    .shapes-title {
        font-size: 24px;
        margin-bottom: 15px; /* Ajustar el margen */
    }

    .button-container {
        flex-direction: column; /* Cambiar a columna */
        width: 100%; /* Ocupa el 100% del ancho */
    }

    .delete-button-shape {
        width: 100%; /* Botón ocupa el 100% */
    }
}

@media (max-width: 576px) {
    

    .shapes-title {
        font-size: 20px; /* Tamaño de fuente más pequeño */
    }

    .form-control {
        padding: 8px; /* Ajustar el padding del input */
    }
}