.blog-block-view {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    width: 100%;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 10px;
    margin-top: 25px;
    padding: 20px;
}

.block-view {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.notice-block {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 10px;
    padding: 15px;
    border-radius: 10px;
    box-shadow: 0px 3px 6px #00000029;
    background-color: #ffffff; /* Fondo blanco para destacar el bloque */
    width: 30%; /* Ancho ajustable según necesidades */
}

.notice-img-block {
    width: 100%;
    height: auto;
    border-radius: 8px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    margin-bottom: 10px;
}

.read-more-button {
    background-color: #007bff;
    color: #fff;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.read-more-button:hover {
    background-color: #0056b3;
}
.detailed-view{
    display: flex;
    flex-direction: column;
    min-height: 100vh; 
}

.banner-detail-container {
    width: 100%;
    max-width: 100vw;
    box-shadow: 0px 3px 6px #00000029;
}

.banner-detail-container img {
    width: 100%; /* Ajusta el tamaño de la imagen al ancho del contenedor */
    height: auto;
    display: block;
    box-shadow: 0px 3px 6px #00000029;
}

.view-notice-content-detail {
    margin: 0 45px;
    padding: 20px;
    background-color: #E6E6E6;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 10px;
    margin-bottom: 25px;
    margin-top: 25px;
    position: relative;
}

.notice-info-top {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px; /* Ajusta según sea necesario */
}

.notice-date {
    text-align: left;
    font-size: 1.2em;
}

.notice-categorie {
    text-align: right;
    font-size: 1.2em;
}

.view-notice-content-detail h1 {
    text-align: center;
    margin-bottom: 20px;
}

.view-notice-content-detail p {
    text-align: center;
    margin-bottom: 20px;
    font-size: 1.2em;
}

/* Media Queries */
@media (max-width: 1200px) {
    .notice-block {
        width: 45%; /* Ajustar el ancho de los bloques */
    }

    .view-notice-content-detail h1,
    .view-notice-content-detail p {
        font-size: 1.1em; /* Reducir el tamaño de fuente en pantallas más pequeñas */
    }
}

@media (max-width: 992px) {
    .notice-block {
        width: 100%; /* Hacer los bloques de ancho completo */
        margin: 5px 0; /* Ajustar el margen */
    }

    .view-notice-content-detail {
        margin: 0 20px; /* Reducir el margen lateral */
    }

    .notice-date,
    .notice-categorie {
        font-size: 1em; /* Ajustar tamaño de fuente */
    }
}

@media (max-width: 768px) {
    .blog-block-view {
        padding: 15px; /* Reducir padding */
    }

    .read-more-button {
        padding: 8px 16px; /* Ajustar padding de botones */
    }
}

@media (max-width: 576px) {
    .view-notice-content-detail {
        padding: 15px; /* Reducir padding */
    }

    .view-notice-content-detail h1 {
        font-size: 1.5em; /* Ajustar tamaño de fuente */
    }

    .view-notice-content-detail p {
        font-size: 1em; /* Ajustar tamaño de fuente */
    }
}