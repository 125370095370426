html, body {
    height: 100%;
    margin: 0;
    padding: 0;
    overflow-x: hidden;
    
}

.about-us-container {
    display: flex;
    flex-direction: column;
    min-height: 100vh; 

}

.about-us-container > *:not(footer) {
    flex: 1; 
}

.banner-about-us div {
    position: relative;
    width: 100%;
    max-width: 100vw;
    box-shadow: 0px 3px 6px #00000029;

}

.banner-about-us img {
    width: 100%; 
    height: auto;
    display: block;
    box-shadow: 0px 3px 6px #00000029;


}

.banner-about-us p {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    color: white; 
    padding: 10px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 4em; 
    background-color: rgba(0, 0, 0, 0.5);
    box-shadow: 0px 3px 6px #00000029;
    margin: 0; 

}

.about-us-elements{
    margin: 0 45px;
    padding: 20px;
    background-color: #E6E6E6;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 10px;  
    margin-bottom: 25px;  
    margin-top: 25px;  

}

.our-company-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    width: 100%;
    box-shadow: 0px 3px 6px #bebebe29;
    border-radius: 10px; 
    margin-top: 10px;
}

.title-company{
    text-align: center;
    margin: 25px;

}


.content-our-company{
    display: flex;
    margin-top: 20px;
    width: 80%;
    flex-wrap: wrap;
    padding: 20px;
}

.content-company-left {
    flex: 1 1 50%;
    padding-right: 20px;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 10px; 

}

.content-company-left h2 {
    margin-bottom: 10px;
    font-size: 18px;
    font-weight: bold;
    font-size: 1.8em; 
    margin: 20px 0;

}

.content-company-left p {
    margin-top: 10px;
    padding: 20px 0;
    font-size: 1.2em; 

}

.content-company-right {
    flex: 1 1 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.content-company-right img {
    max-width: 100%;
    height: 100%;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 10px; 
}

/* Media Queries para responsividad */
@media (max-width: 768px) {
    .about-us-elements {
        margin: 0 20px; /* Reducir márgenes en dispositivos más pequeños */
    }

    .content-our-company {
        width: 100%; /* Ocupa todo el ancho en dispositivos pequeños */
    }

    .content-company-left {
        flex: 1 1 100%; /* Las secciones ocupan el 100% del ancho */
        padding-right: 0; /* Quitar padding derecho */
        margin-bottom: 20px; /* Espacio entre secciones */
    }

    .banner-about-us p {
        font-size: 2em; /* Ajustar tamaño de fuente para dispositivos más pequeños */
    }
}

@media (max-width: 480px) {
    .banner-about-us p {
        font-size: 1.5em; /* Ajustar aún más en pantallas pequeñas */
    }

    .title-company {
        margin: 15px; /* Reducir márgenes en pantallas pequeñas */
    }

    .content-company-left h2 {
        font-size: 1.5em; /* Ajustar título para pantallas pequeñas */
    }

    .content-company-left p {
        font-size: 1em; /* Ajustar tamaño del texto para pantallas pequeñas */
    }
}