.background-container-notices {
    width: 100%;
    height: 100%;
    background: #F1F1F1;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 22px;
    opacity: 1;
    padding: 20px; 
}

.notices-control-title {
    text-align: center;
    font: normal normal bold 40px/47px Helvetica;
    letter-spacing: 0px;
    color: #707070;
    opacity: 1;
    margin-bottom: 20px; 
}

.notices-form {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.custom-date-input {
    width: 100%;
    padding: 0.5rem;
    font-size: 1rem;
    border-radius: 5px;
    border: 1px solid #ccc;
}

/* Custom styles for the ReactQuill editor */
.custom-quill-editor {
    height: 300px; /* Adjust the height as needed */
    width: 100%;
    margin-bottom: 60px;   
}

/* General form control styles */
.form-control-notice {
    width: 100%;
    padding: 0.5rem;
    font-size: 1rem;
    border-radius: 5px;
    border: 1px solid #ccc;
    margin-bottom: 15px;
}

.delete-button-notice {
    width: 100%;
    height: 40px;
    background-color: red;
    color: white;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
}

/* Media Queries para responsividad */
@media (max-width: 768px) {
    .notices-control-title {
        font-size: 32px; /* Reducir tamaño de fuente para pantallas más pequeñas */
    }

    .custom-quill-editor {
        height: 250px; /* Ajustar la altura del editor en pantallas pequeñas */
    }

    .delete-button-notice {
        height: 35px; /* Ajustar la altura del botón en pantallas pequeñas */
    }
}

@media (max-width: 480px) {
    .notices-control-title {
        font-size: 24px; /* Reducir aún más el tamaño de la fuente */
    }

    .custom-quill-editor {
        height: 200px; /* Ajustar la altura del editor para pantallas muy pequeñas */
    }

    .delete-button-notice {
        height: 30px; /* Ajustar la altura del botón en pantallas muy pequeñas */
    }
}