/* RequestPasswordReset.css */

.reset-password-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 400px; /* Ajusta el ancho según sea necesario */
    margin: 0 auto; /* Centra el contenedor */
    padding: 20px; /* Espaciado interior */
    border: 1px solid #ccc; /* Borde */
    border-radius: 8px; /* Esquinas redondeadas */
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); /* Sombra */
    background-color: #f9f9f9; /* Color de fondo */
}

.reset-password-container p {
    font-size: 1em; /* Tamaño de fuente */
    margin-bottom: 20px; /* Espacio debajo del texto */
}

.reset-password-container input {
    width: 100%; /* Ancho completo */
    padding: 10px; /* Espaciado interior */
    margin-bottom: 10px; /* Espacio entre el input y el botón */
    border: 1px solid #ccc; /* Borde del input */
    border-radius: 5px; /* Esquinas redondeadas */
    font-size: 1em; /* Tamaño de fuente */
}

.reset-password-container button {
    padding: 10px 15px; /* Espaciado interior del botón */
    border: none; /* Sin borde */
    border-radius: 5px; /* Esquinas redondeadas */
    background-color: #007bff; /* Color de fondo del botón */
    color: white; /* Color del texto */
    font-size: 1em; /* Tamaño de fuente */
    cursor: pointer; /* Cambia el cursor al pasar sobre el botón */
    transition: background-color 0.3s; /* Efecto de transición */
}

.reset-password-container button:hover {
    background-color: #0056b3; /* Color de fondo al pasar el ratón */
}

.error-message {
    color: red;
    font-size: 14px;
    margin-top: 10px;
}

