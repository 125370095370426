html, body {
    height: 100%;
    margin: 0;
    padding: 0;
    overflow-x: hidden; /* Evita el desplazamiento horizontal */
}

.blog-container {
    display: flex;
    flex-direction: column;
    min-height: 100vh; /* Asegura que el contenedor ocupe al menos el alto de la pantalla */
}

.blog-container > *:not(footer) {
    flex: 1; /* Permite que todos los elementos hijos, excepto el footer, crezcan para ocupar el espacio disponible */
}

.banner-blog div {
    position: relative;
    width: 100%;
    max-width: 100vw; /* Asegura que no se exceda el ancho de la ventana */
    box-shadow: 0px 3px 6px #00000029; /* Sombra del contenedor */
}

.banner-blog img {
    width: 100%; /* Ajusta el tamaño de la imagen al ancho del contenedor */
    height: auto; /* Mantiene la proporción de la imagen */
    display: block; /* Elimina el espacio debajo de la imagen */
    box-shadow: 0px 3px 6px #00000029; /* Sombra de la imagen */
}

.banner-blog p {
    position: absolute; /* Posiciona el texto sobre la imagen */
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    color: white; 
    padding: 10px;
    text-align: center; /* Centra el texto */
    display: flex;
    align-items: center; /* Centra verticalmente el texto */
    justify-content: center; /* Centra horizontalmente el texto */
    font-size: 4em; /* Tamaño de la fuente grande */
    background-color: rgba(0, 0, 0, 0.5); /* Fondo semitransparente */
    box-shadow: 0px 3px 6px #00000029; /* Sombra del texto */
    margin: 0; /* Quita el margen */
}

/* Ajuste para el contenedor de componentes del blog */
.components-elements-blog {
    margin: 0 45px; /* Margen horizontal */
    padding: 20px; /* Relleno interno */
    background-color: #E6E6E6; /* Color de fondo */
    box-shadow: 0px 3px 6px #00000029; /* Sombra */
    border-radius: 10px; /* Bordes redondeados */
    margin-bottom: 25px; /* Margen inferior */
    margin-top: 25px; /* Margen superior */
}

/* Media Queries para mejorar la responsividad */
@media (max-width: 768px) {
    .banner-blog p {
        font-size: 2em; /* Ajusta el tamaño de la fuente en pantallas pequeñas */
    }

    .components-elements-blog {
        margin: 0 20px; /* Reduce el margen horizontal en pantallas pequeñas */
        padding: 15px; /* Reduce el padding interno en pantallas pequeñas */
    }
}
