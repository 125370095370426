html, body {
    height: 100%;
    margin: 0;
    padding: 0;
    overflow-x: hidden;
}

.background-container-search {
    width: 100%;
    height: 100%;
    background: #F1F1F1;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 22px;
    opacity: 1;
    padding: 20px; 
    margin-top: 25px;
}

.search-content {
    display: flex;
    width: 100%;
}

.search-filters {
    flex: 1;
    padding: 20px;
    border-right: 1px solid #ddd; /* Optional: Adds a border between the filters and the product list */
    height: calc(100vh - 60px); /* Adjust the height if needed */
    overflow-y: auto; /* Adds a scrollbar if needed */
}

.product-list-container {
    flex: 3;
    padding: 20px;
    display: flex;
    flex-direction: column;
}

.search-info {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.search-info h1 {
    text-align: center;
    font: normal normal bold 40px/47px Helvetica;
    letter-spacing: 0px;
    color: #707070;
    opacity: 1;
    margin-bottom: 20px; 
}

.search-info p {
    font-size: 1.4em; 
    margin-bottom: 10px;
}

.search-elements-filters {
    display: grid;
    grid-template-columns: repeat(3, 1fr); 
    gap: 15px; 
}

.search-elements-filters select,
.search-elements-filters button {
    width: 100%; 
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #ddd;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.search-elements-filters button {
    background-color: #f8f9fa;
    cursor: pointer;
    transition: background-color 0.3s;
    align-items: center;
}

.search-elements-filters button:hover {
    background-color: #e2e6ea;
}

.pagination-controls {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px; 
    margin: 20px 0; 
}

.filter-button-container {
    grid-column: 2; 
    display: flex;
    justify-content: center; 
}

.pagination-controls button,
.pagination-controls select {
    margin: 0 5px;
    align-items: center;
}

.pagination-controls span {
    margin: 0 10px; 
}

.product-list-search {
    padding: 30px;
    display: flex;
    flex-direction: column;
    gap: 15px;
    border-radius: 15px; 
    border: 1px solid #ddd; 
    overflow: hidden; 
    background: #fff; 
}

.product-item-search {
    display: flex;
    align-items: center;
    border-bottom: 1px solid #ddd;
    padding: 5px 0;
}

.product-image-container-search {
    position: relative;
    margin-right: 20px;
    width: 30%; 
    height: 50%; 
    overflow: hidden;
    cursor: pointer;

}

.center-image {
    width: 80%;
    height: 80%;
    object-fit: cover;
    transition: opacity 0.3s ease;
}

.side-imagen {
    position: absolute;
    top: 0;
    left: 0;
    width: 80%;
    object-fit: cover;
    opacity: 0;
    transition: opacity 0.3s ease;
}

.product-item-search:hover .side-imagen {
    opacity: 1;
}

.product-item-search:hover .center-image {
    opacity: 0;
}

.product-details-search {
    display: flex;
    align-items: center;
    margin: 5%;

}

.description-list {
    flex: 1;
    padding-right: 50px;
}

.products-buy-details {
    display: flex;
    flex-direction: column;
    align-items: center; 
    justify-content: center; 
    margin-right: 65px;
}

.product-info-search {
    font: normal normal normal 22px/29px Roboto;
    color: #555;
    margin: 0; 
}
.product-price {
    font-size: 50px; 
    font: normal normal normal 32px/29px Roboto;
}

.shop-control-elements {
    display: flex;
    flex-direction: column; 
    align-items: center; 
    margin-top: 5px; 
    margin-bottom: 5px;
}

.button-container {
    margin-bottom: 5px; 
}

.quantity-selector-container {
    display: flex;
    flex-direction: row; 
    margin-left:5%;
    align-items: center;
    gap: 15px; 
    width: 100%; 
}

.quantity-selector-wrapper {
    position: relative;
    display: inline-block;
}
.quantity-input {
    width: 45px;
    text-align: center;
    border: 1px solid #ddd;
    padding: 5px;
}
.price-overlay {
    display: flex;
    flex-direction: column; 
    text-align: center;
    align-items: center;
}
.product-price-discount {
    color:#707070;
    
}

.shop-control-elements {
    color:#414040;
    margin: 0; 
}

/* Estilos para pantallas medianas (tablets y pantallas más pequeñas) */
@media (max-width: 1024px) {
    .search-filters {
        flex: 1;
        padding: 15px;
        border-right: none;
        height: auto;
    }

    .product-list-container {
        flex: 3;
        padding: 15px;
    }

    .search-elements-filters {
        grid-template-columns: 1fr 1fr;
    }

    .filter-button-container {
        grid-column: span 2;
    }

    .product-item-search {
        flex-direction: column;
        align-items: flex-start;
    }

    .product-image-container-search {
        width: 100%;
        margin-right: 0;
        margin-bottom: 15px;
    }

    .product-details-search {
        margin: 0;
        padding: 0;
    }

    .products-buy-details {
        flex-direction: row;
        justify-content: space-between;
        margin-right: 0;
        width: 100%;
    }

    .quantity-selector-container {
        margin-left: 0;
        width: 100%;
        justify-content: space-between;
    }

    .description-list {
        padding-right: 20px;
    }

    .shop-control-elements {
        margin: 10px 0;
    }
}

/* Estilos para pantallas pequeñas (smartphones y dispositivos pequeños) */
@media (max-width: 768px) {
    .search-content {
        flex-direction: column;
    }

    .search-filters {
        width: 100%;
        padding: 10px;
        border-right: none;
    }

    .product-list-container {
        width: 100%;
        padding: 10px;
    }

    .search-elements-filters {
        display: flex;
        flex-direction: column;
        gap: 10px; /* Espacio entre los elementos */
    }

    .product-item-search {
        flex-direction: column;
        align-items: flex-start;
    }

    .product-image-container-search {
        width: 100%;
        height: auto;
    }

    .center-image,
    .side-imagen {
        width: 100%;
        height: auto;
    }

    .product-details-search {
        flex-direction: column;
        align-items: flex-start;
        margin: 10px 0;
    }

    .products-buy-details {
        margin-right: 0;
        width: 100%;
    }

    .quantity-selector-container {
        flex-direction: column;
        align-items: flex-start;
        margin-left: 0;
        width: 100%;
    }

    .description-list {
        padding-right: 0;
        margin-bottom: 15px;
    }

    .product-price {
        font-size: 40px;
    }

    .shop-control-elements {
        flex-direction: row;
        justify-content: space-between;
        width: 100%;
    }

    .pagination-controls {
        flex-direction: column;
        gap: 5px;
    }
}
