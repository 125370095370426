body, html {
    height: 100%;
    margin: 0;
    padding: 0;
    overflow-x: hidden; 
}

.account-content{
    margin: 0 45px;
    padding: 20px;
    background-color: #E6E6E6;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 10px;  
    margin-bottom: 25px;  
    margin-top: 25px; 
}

.account-content-background{
    width: 100%;
    height: 100%;
    background: #FFFFFF;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 22px;
    opacity: 1;
    padding: 20px; 
    margin-top: 15px;
}

.account-content-header{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
    color: #707070;
    opacity: 1;
}

.account-elements {
    display: flex;
    background: #FFFFFF;
    border-radius: 22px;
    box-shadow: 0px 3px 6px #00000029;
    overflow: hidden;
}

.account-elements-data-users {
    width: 30%;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-right: 1px solid #E6E6E6;
}

.account-elements-data-users img {
    width: 40px;
    height: 40px;
    margin-bottom: 10px;
}

.elements-account-control {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    text-align: center;
    color: #707070;
}

.orders-element, .info-element {
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 10px;
    cursor: pointer;
    border-radius: 10px;
    transition: background 0.3s;
}
.orders-element  p {
    margin: 0;
    font-size: 26px;
    color: #707070; 
}

.info-element p {
    margin: 0;
    font-size: 26px;
    color: #707070; 
}

.orders-element:hover, .info-element:hover {
    background: #F5F5F5;
}

.account-elements-data-info {
    width: 70%;
    padding: 20px;
}

/* Estilo para las órdenes */
.orders-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 10px;
}

.orders-table th, .orders-table td {
    padding: 10px;
    text-align: left;
    border-bottom: 1px solid #E6E6E6;
}

.orders-table th {
    background: #F5F5F5;
    font-weight: bold;
}

.orders-table tr:hover {
    background: #FAFAFA;
}

table {
    width: 100%;
    margin-top: 15px;
}

th, td {
    border: 1px solid #ddd;
    padding: 22px;
    text-align: left;
}

th {
    background-color: #f4f4f4;
    font-weight: bold;
}

tr:nth-child(even) {
    background-color: #f9f9f9;
}

.wompi-payment-button td {
    display: flex;
    gap: 8px; 
    align-items: center;
    justify-content: center; 
}

.orders-table .btn {
    padding: 5px 10px;
    border-radius: 5px;
    font-size: 14px;
    cursor: pointer;
    margin: 5px;
}

.orders-table .btn-confirm {
    background-color: #4CAF50;
    color: white;
    border: none;
}

.orders-table .btn-delete {
    background-color: #f44336;
    color: white;
    border: none;
}

.order-products-container h3{
    text-align: center;
    font: normal normal medium 53px/70px Roboto;
    letter-spacing: 0px;
    color: #707070;
    opacity: 1;
}


.btn-confirm-state {
    margin-top: 10px;
    background-color: #007BFF; 
    color: white; 
    font-size: 16px; 
    padding: 9px 15px; 
    border: none; 
    border-radius: 5px; 
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.btn-confirm-state:hover {
    background-color: #0056b3; 
}

.btn-confirm-state:disabled {
    background-color: #ccc; 
    cursor: not-allowed; 
}
