.banners-container-get {
    width: 100%;
    margin: 0 auto; /* Centra el contenedor en la página */
}

.banner-wrapper-get {
    position: relative;
    margin-bottom: 20px; /* Espacio entre banners */
}

.banners-img-get {
    width: 100%;
    max-height: 600px; /* Ajusta la altura máxima de los banners */
    object-fit: cover; /* Ajusta el modo de ajuste de la imagen */
}

.button-container-get {
    position: absolute;
    bottom: 10px;
    left: 48%;
    transform: translateX(-50%);
    text-align: center;
}

.button-container-get .btn {
    display: inline-block; /* Asegura que el botón se muestre como un bloque inline */
    padding: 10px 20px; /* Ajusta el padding según sea necesario */
    background-color: #007bff; /* Color de fondo del botón */
    color: #fff; /* Color del texto del botón */
    border: none; /* Quita el borde del botón */
    border-radius: 5px; /* Borde redondeado del botón */
    text-decoration: none; /* Quita subrayado del enlace */
}

/* Media Queries */
@media (max-width: 1200px) {
    .banners-img-get {
        max-height: 500px; /* Reduce la altura máxima de los banners en pantallas más pequeñas */
    }

    .button-container-get .btn {
        padding: 8px 16px; /* Ajusta el padding del botón */
    }
}

@media (max-width: 992px) {
    .banners-img-get {
        max-height: 400px; /* Reduce la altura máxima de los banners aún más */
    }

    .button-container-get {
        bottom: 15px; /* Ajusta la posición del botón */
    }
}

@media (max-width: 768px) {
    .banners-img-get {
        max-height: 300px; /* Altura máxima para dispositivos móviles */
    }

    .button-container-get {
        bottom: 20px; /* Ajusta la posición del botón */
    }

    .button-container-get .btn {
        padding: 6px 12px; /* Reduce el padding del botón */
        font-size: 0.9em; /* Reduce el tamaño de la fuente del botón */
    }
}

@media (max-width: 576px) {
    .button-container-get {
        left: 50%; /* Centra el botón en pantallas pequeñas */
        transform: translateX(-50%);
    }

    .button-container-get .btn {
        padding: 5px 10px; /* Ajusta aún más el padding del botón */
        font-size: 0.8em; /* Reduce el tamaño de la fuente del botón */
    }
}
