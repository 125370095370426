    .background-container-shop {
        width: 100%;
        height: 100%;
        background: #F1F1F1;
        box-shadow: 0px 3px 6px #00000029;
        border-radius: 22px;
        opacity: 1;
        padding: 20px; 
        margin-top: 25px;
    }

    .fullscreen-modal {
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        background-color: rgba(0, 0, 0, 0.801); /* Fondo negro completo */
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 9999;
    }

    .fullscreen-content {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
    }

    .fullscreen-image {
        max-width: 80vw;
        max-height: 80vh;
        object-fit: contain;
        border-radius: 10px;
    }


    .close-button {
        position: absolute;
        top: 10px;
        right: 45px;
        border: none;
        border-radius: 50px;
        background: rgba(0, 0, 0, 0.596);
        color: white; 
        font-size: 2rem;
        font-weight: bold;
        cursor: pointer;
        padding: 10px;
        background: none; 
    }

    .arrow-button {
        position: absolute;
        font-size: 1rem;
        top: 50%;
        transform: translateY(-50%);
        border: none;
        border-radius: 100%;
        background: rgba(0, 0, 0, 0.596);
        color: white;
        cursor: pointer;
        z-index: 1000;
        padding: 10px;
    }

    .pagination-controls {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 10px; 
        margin: 20px 0; 
    }

    .pagination-controls button,
    .pagination-controls select {
        margin: 0 5px;
    }

    .pagination-controls span {
        margin: 0 10px; 
    }


    .product-list-shop {
        padding: 30px;
        display: flex;
        flex-direction: column;
        gap: 15px;
        border-radius: 15px; 
        border: 1px solid #ddd; 
        overflow: hidden; 
        background: #fff; 
    }

    .product-item-shop {
        display: flex;
        align-items: center;
        border-bottom: 1px solid #ddd;
        padding: 5px 0;
    }

    .product-image-container-shop {
        position: relative;
        margin-right: 20px;
        width: 30%; 
        height: 50%; 
        overflow: hidden;
        cursor: pointer;

    }

    .center-image {
        width: 80%;
        height: 80%;
        object-fit: cover;
        transition: opacity 0.3s ease;
    }

    .side-imagen {
        position: absolute;
        top: 0;
        left: 0;
        width: 80%;
        object-fit: cover;
        opacity: 0;
        transition: opacity 0.3s ease;
    }

    .product-item-shop:hover .side-imagen {
        opacity: 1;
    }

    .product-item-shop:hover .center-image {
        opacity: 0;
    }

    .product-details-shop {
        display: flex;
        align-items: center;
        margin: 5%;

    }

    .description-list {
        flex: 1;
        padding-right: 50px;
    }

    .products-buy-details {
        display: flex;
        flex-direction: column;
        align-items: center; 
        justify-content: center; 
        margin-right: 65px;
    }

    .product-info-shop {
        font: normal normal normal 22px/29px Roboto;
        color: #555;
        margin: 0; 
    }
    .product-price {
        font-size: 50px; 
        font: normal normal normal 32px/29px Roboto;
    }

    .shop-control-elements {
        display: flex;
        flex-direction: column; 
        align-items: center; 
        margin-top: 5px; 
        margin-bottom: 5px;
    }

    .button-container {
        margin-bottom: 5px; 
    }

    .quantity-selector-container {
        display: flex;
        flex-direction: row; 
        margin-left:5%;
        align-items: center;
        gap: 15px; 
        width: 100%; 
    }

    .quantity-selector-wrapper {
        position: relative;
        display: inline-block;
    }
    .quantity-input {
        width: 45px;
        text-align: center;
        border: 1px solid #ddd;
        padding: 5px;
    }
    .price-overlay {
        display: flex;
        flex-direction: column; 
        text-align: center;
        align-items: center;
    }
    .product-price-discount {
        color:#707070;
        
    }

/* Estilos para pantallas pequeñas (smartphones y dispositivos pequeños) */
@media (max-width: 768px) {
    .background-container-shop {
        padding: 10px;
        margin-top: 15px;
    }

    .fullscreen-modal {
        padding: 10px;
    }

    .fullscreen-image {
        max-width: 90vw;
        max-height: 60vh;
    }

    .product-list-shop {
        padding: 15px;
    }

    .product-item-shop {
        flex-direction: column;
        align-items: flex-start;
        gap: 10px;
    }

    .product-image-container-shop {
        width: 100%;
        height: auto;
        margin-right: 0;
    }

    .center-image,
    .side-imagen {
        width: 100%;
        height: auto;
    }

    .product-details-shop {
        flex-direction: column;
        align-items: flex-start;
        margin: 10px 0;
    }

    .description-list {
        padding-right: 0;
        margin-bottom: 10px;
    }

    .products-buy-details {
        margin-right: 0;
        width: 100%;
    }

    .product-price {
        font-size: 30px;
    }

    .shop-control-elements {
        flex-direction: row;
        justify-content: space-between;
        width: 100%;
    }

    .pagination-controls {
        flex-direction: column;
        gap: 5px;
    }

    .quantity-selector-container {
        flex-direction: column;
        align-items: flex-start;
        margin-left: 0;
        width: 100%;
    }

    .close-button {
        top: 5px;
        right: 10px;
        font-size: 1.5rem;
        padding: 5px;
    }

    .arrow-button {
        font-size: 0.8rem;
        padding: 5px;
    }
}
