html, body {
    height: 100%;
    margin: 0;
    padding: 0;
    overflow-x: hidden;
    
}

.home-ecommerce {
    display: flex;
    flex-direction: column;
    min-height: 100vh; 

}

.home-ecommerce > *:not(footer) {
    flex: 1; 
}
.ecommerce-elements-home {
    margin: 0 45px;
    padding: 20px;
    background-color: #E6E6E6;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 10px;  
    margin-bottom: 25px;  
    margin-top: 25px; 

}

.container-images-e-commerce {
    display: flex;
    justify-content: space-between;
    align-items: flex-start; 
    flex-wrap: nowrap; 
    padding: 10px;
    gap: 20px;
    cursor: pointer;
    margin-top: 20px;

}

.container-images-e-commerce div {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    width: 100%;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 10px;    

}

.container-images-e-commerce img {
    width: 90%; 
    height: auto;
    margin-bottom: 10px;
    border-radius: 10px;    
    box-shadow: 0px 3px 6px #00000029;

}

.container-images-e-commerce p {
    text-align: center;
    font: normal normal normal 34px/35px Bell MT;
    letter-spacing: 0px;
    color: #707070;
}

.container-images-e-commerce div {
    transition: transform 0.3s, box-shadow 0.3s;
}

.container-images-e-commerce div:hover {
    transform: scale(1.05); 
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); 
}

.discount-element{
    position: relative;
    width: 100%;
    max-width: 100vw;
    border-radius: 10px;    
    box-shadow: 0px 3px 6px #00000029;
    cursor: pointer;
    margin-top: 20px;

}
.discount-element img {
    width: 100%; 
    height: auto;
    display: block;
    border-radius: 10px;    
    box-shadow: 0px 3px 6px #00000029;
}

.discount-element div {
    transition: transform 0.3s, box-shadow 0.3s;
}

.discount-element div:hover {
    transform: scale(1.05); 
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); 
}


.filter-elements {
    display: flex;
    justify-content: space-between;
    align-items: flex-start; 
    flex-wrap: nowrap; 
    padding: 10px;
    gap: 20px;
    cursor: pointer;
    margin-top: 20px;
    text-decoration: none;

}

.filter-elements div {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    width: 100%;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 10px;    

}

.filter-elements img {
    width: 90%; 
    height: auto;
    margin-bottom: 10px;
    border-radius: 10px;    
    box-shadow: 0px 3px 6px #00000029;

}
.filter-elements a {
    text-decoration: none; 
    color: inherit; 
}

.filter-elements p {
    text-align: center;
    font: normal normal normal 34px/35px Bell MT;
    letter-spacing: 0px;
    color: #707070;
    text-decoration: none;
}

.filter-elements div {
    transition: transform 0.3s, box-shadow 0.3s;
}

.filter-elements div:hover {
    transform: scale(1.05); 
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); 
}


/* responsive */
@media (max-width: 768px) {
    .container-images-e-commerce {
        flex-direction: column; /* Cambiar a columna en pantallas pequeñas */
        align-items: center; /* Centrar elementos */
    }
    
    .container-images-e-commerce div {
        width: 100%; /* Hacer los elementos ocupar el 100% del ancho */
    }

    .filter-elements {
        flex-direction: column; /* Cambiar a columna en pantallas pequeñas */
        align-items: center; /* Centrar elementos */
    }

    .filter-elements div {
        width: 100%; /* Hacer los elementos ocupar el 100% del ancho */
    }

    .home-ecommerce {
        margin: 0; /* Eliminar margen en pantallas pequeñas */
        padding: 0 10px; /* Añadir un poco de padding */
    }
    
    .ecommerce-elements-home {
        margin: 0 20px; /* Reducir márgenes en pantallas pequeñas */
    }
}

.discount-element img,
.container-images-e-commerce img,
.filter-elements img {
    width: 100%; /* Asegurar que ocupen todo el ancho disponible */
    height: auto; /* Mantener la relación de aspecto */
}
