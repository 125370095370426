
.background-user-container {
    width: 100%;
    height: 100%;
    background: #F1F1F1;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 22px;
    opacity: 1;
    padding: 20px; /* Ajusta el padding según sea necesario */
}


.user-title {
    text-align: center;
    font: normal normal bold 40px/47px Helvetica;
    letter-spacing: 0px;
    color: #707070;
    opacity: 1;
    margin-bottom: 20px; /* Ajusta el margen según sea necesario */
}


.user-details .user-summary {
    cursor: pointer;
    padding: 10px;
    border-bottom: 1px solid #ccc;
}

.user-full-details {
    padding: 10px;
    border-top: 1px solid #ccc;
}

.pdf-viewer {
    margin-top: 10px;
}

.modal-body {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.user-details-table {
    width: 100%;
    border-collapse: collapse;
}

.user-details-table th,
.user-details-table td {
    border: 1px solid #ccc;
    padding: 8px;
    text-align: left;
}

.user-details-table th {
    background-color: #f2f2f2;
    font-size: 16px;
}

@media (max-width: 576px) {
    
    .user-title {
        font-size: 20px; /* Tamaño de fuente más pequeño */
    }

    .user-details .user-summary {
        padding: 4px; /* Ajustar padding */
    }

    .user-details-table th,
    .user-details-table td {
        padding: 4px; /* Reducir padding en las celdas */
    }

    .modal-body {
        padding: 10px; /* Añadir padding para la modal */
    }
}