/* styles-intranet/BannersUpload.css */
.background-container {
    width: 100%;
    height: 100%;
    background: #F1F1F1;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 22px;
    opacity: 1;
    padding: 20px; /* Ajusta el padding según sea necesario */
}

.banner-title {
    text-align: center;
    font: normal normal bold 40px/47px Helvetica;
    letter-spacing: 0px;
    color: #707070;
    opacity: 1;
    margin-bottom: 20px; /* Ajusta el margen según sea necesario */
}

.banner-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    padding: 10px;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 10px;
    background-color: white;
}

.banner-item p {
    word-wrap: break-word; /* Permite que las palabras largas se rompan y ajusten */
    overflow-wrap: break-word; /* Asegura que el texto se ajuste dentro del contenedor */
}

.banner-image {
    width: 100%;
    border-radius: 10px;
    opacity: 1;
    margin-bottom: 10px;
}
.button-container {
    display: flex;
    justify-content: center; /* Centra el botón horizontalmente */
    margin-top: 10px;
    width: 100%; /* Asegura que el contenedor use todo el ancho disponible */
}

.delete-button-banners {
    width: 50%;
    height: 40px;
    background-color: red;
    color: white;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
}
