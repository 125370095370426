.order-summary {
    display: flex;
    align-items: center;
    padding: 6px;
    gap: 10px;
    border: 1px solid #ddd;
    border-radius: 8px;
    background-color: #f9f9f9;
    cursor: pointer;
    transition: background-color 0.3s ease-in-out;
    margin-bottom: 10px;

}

.title-container{
    text-align: center;
    font: normal normal medium 53px/70px Roboto;
    letter-spacing: 0px;
    color: #707070;
    opacity: 1;
}
.order-summary:hover {
    background-color: #eaeaea;
}

.order-summary p {
    margin: 0;
    padding: 5px 0;
    font-size: 15px;
    font-weight: 400;
    margin-right: auto;
}

.toggle-details {
    text-align: right;
    font-weight: bold;
    color: #007bff;
    cursor: pointer;
}

.toggle-details:hover {
    color: #0056b3;
}

.order-details table {
    width: 100%;
    margin-top: 10px;
    margin: 0 auto;
    text-align: center; 
    border-collapse: collapse;
    align-items: center;
}

.order-details th, .order-details td {
    padding: 8px 12px;
    vertical-align: middle;
    border: 1px solid #ddd;
    align-items: center;
    text-align: center;
    

}

.order-details th {
    background-color: #f4f4f4; 
    font-weight: bold;
    align-items: center;

}