:root {
    --secondary-color: #blue; 
    --primaryColor: #white;
}

.menu-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 4px;
    opacity: 1;
    background-color: var(--primaryColor);
    position: relative;
    width: 100%;

}

.logo-img {
    height : 100px;
    margin-right: 70px;
    object-fit: contain;
}

.menu-nav {
    display: flex;
    align-items: center;
    margin-left: 10px;
}

.menu-items {
    margin-right: 20px;
    display: flex;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 1.3em;
}

.menu-item {
    margin-top: 10px;
    margin-right: 35px; 
    color: var(--secondary-color); 
    text-decoration: none; 
    transition: color 0.3s; 
    cursor: pointer;

}

.menu-item:hover {
    color: white; 
}

.login-container {
    margin-left: auto;
}

.btn-dark {
    display: inline-block;
    margin-right: 15px;
    align-items: center;
    justify-content: space-between;
    font-size: 1em; 
}

.form-toggle {
    display: flex;
    justify-content: center;
    margin-bottom: 10px;
}

.form-toggle-item {
    margin:  15px;
    cursor: pointer;
    padding: 10px 20px;
    border-radius: 5px;
    border: 2px solid transparent;
    transition: background-color 0.3s, color 0.3s;
}

.form-toggle-item.active {
    background-color: #28a745; 
    color: white;
    border-color: #28a745;
}

.form-toggle-item:not(.active):hover {
    background-color: #f0f0f0;
    border-color: #ccc;
}   
.submenu {
    display: flex;
    flex-direction: row-reverse; 
    justify-content: center; 
    position: absolute; 
    top: 100%; 
    left: 0;
    background-color: var(--primaryColor); 
    z-index: 1000;
    padding: 0.5rem 0; 
    width: 100%;
    cursor: pointer;

}

.submenu-item {
    margin-right: 20px;
    color: var(--secondary-color); 
    text-decoration: none; 
    transition: color 0.3s; 
    padding: 0.5rem 1rem;
}


.submenu-item:hover {
    color: white; 
}

.language-toggle {
    margin-top: 10px;
    margin-right: 35px; 
    color: var(--secondary-color); 
    text-decoration: none; 
    transition: color 0.3s; 
    cursor: pointer;
    padding: 0 5px;
}

.language-toggle select {
    cursor: pointer;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 2px;
}

.hamburger {
    display: none; /* Ocultar el menú hamburguesa en pantallas grandes */
}
@media (max-width: 768px) {
    .hamburger {
        display: block; /* Mostrar el menú hamburguesa en pantallas pequeñas */
        color: var(--secondary-color); 

    }

    .menu-items {
        display: none; /* Ocultar menú por defecto en pantallas pequeñas */
        flex-direction: column; /* Cambiar a columna para menú hamburguesa */
        position: absolute; /* Posicionarlo correctamente */
        top: 100%; /* Bajo el logo */
        left: 50%; /* Centrar el menú */
        transform: translateX(-50%); /* Ajustar el centrado */
        width: 100%; 
        z-index: 1000; /* Asegurar que esté por encima */
        background-color: var(--primaryColor); /* Asegurarse de que tenga un color de fondo por defecto */
        padding: 5px 10px;  /* Espaciado interno */
        transition: opacity 0.3s ease; /* Suavizar la transición */
        align-items: center;
    }

    .login-container {
        display: flex;
        flex-direction: center;
        align-items: center;
        margin-left: 10px;
    }

    .current-buttons-control {
        width: 48%;
        align-items: center;
    }
    .menu-items.open {
        display: flex; /* Mostrar menú hamburguesa cuando está abierto */
        justify-content: center;
        align-items: center;
        opacity: 1; /* Hacerlo visible */
        background-color: var(--primaryColor); /* Asegurarse de que tenga el color de fondo correcto */
    }

    .menu-item {
        margin: 15px 0; /* Espaciado en vertical */
        text-align: center; /* Alinear el texto al centro */
        align-items: center;
        width: auto; /* No ocupar todo el ancho */
    }

    .submenu {
        display: flex;
        flex-direction: column; /* Alinear los submenús verticalmente */
        justify-content: center;
        align-items: center;
        width: 100%; /* Ajustar el ancho del submenú */
    
    }

    .submenu-item {
        width: auto; /* No ocupar todo el ancho */
        padding: 10px 20px; /* Espaciado interno */
        text-align: center; /* Alinear el texto del submenú */
    }

    .language-toggle {
        display: flex;
        justify-content: center; /* Centrar el selector */
        margin-right: 10px;
        width: 100%;
    }

    .language-toggle select {
        background-color: #fff;
        border: 1px solid #ccc;
        border-radius: 4px;
        text-align: center; 
    }
}

.user-icon {
    background-color: #f0f0f0;
    border-radius: 50%;
    margin-right: 10px;
    width: 50px;
    padding: 10px;
    height: 50px;
    margin-top: 5px;
    cursor: pointer;
}



