body, html {
    height: 100%;
    margin: 0;
    padding: 0;
    overflow-x: hidden; 
}

.container-content-orders{
    margin: 0 45px;
    padding: 20px;
    background-color: #E6E6E6;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 10px;  
    margin-bottom: 25px;  
    margin-top: 25px; 
}

.container-background-orders{
    width: 100%;
    height: 100%;
    background: #FFFFFF;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 22px;
    opacity: 1;
    padding: 20px; 
    margin-top: 15px;
}

.order-state-title{
    text-align: center;
    font: normal normal medium 53px/70px Roboto;
    letter-spacing: 0px;
    color: #707070;
    opacity: 1;
}

.order-state-content{
    display: flex;
    flex-wrap: wrap;
    padding: 25px;
    justify-content: space-around;
    align-items: center;
}

.order-state-products h2{
    text-align: center;
    font: normal normal medium 53px/70px Roboto;
    letter-spacing: 0px;
    color: #707070;
    opacity: 1;
}
.order-items-container {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: space-around;
    align-items: center;
}

.order-item {
    display: flex;
    flex-direction: row;
    background: white;
    border: 1px solid #ddd;
    border-radius: 10px;
    height: 118px;
    padding: 25px;
    width: 60%; 
    text-align: center;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1);
}

.order-item img {
    border-radius: 5px;
    margin-bottom: 10px;
    width: 100%;
    height: 70px;
    object-fit: cover;
}

